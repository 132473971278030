import React from "react";
import HeaderPage from "./HeaderPage";
import FooterPages from "./FooterPages";

function Layout(props) {
  return (
    <div className="max-w-7xl mx-auto font-Mohave">
      <title>{props.title}</title>
      <HeaderPage />
      {props.children}
      <FooterPages />
    </div>
  );
}

export default Layout;
